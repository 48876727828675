<template>
	<v-layout align-start>
		<v-container>
			<v-row class="pl-2 mb-0" align="center">			
				<v-btn
					text
					icon
					style="color: #909090;"
					@click="back"
				>
					<v-icon style="color: #909090">mdi-arrow-left-bold</v-icon>
				</v-btn>
				<v-breadcrumbs class="pl-1" :items="items">
					<template v-slot:item="{ item }">
						<v-breadcrumbs-item
							:to="item.href"
							:disabled="item.disabled"
						>
							{{ item.text}}
						</v-breadcrumbs-item>
					</template>
				</v-breadcrumbs>
			</v-row>
			<v-toolbar
				flat
			>
				<v-toolbar-title>Mensualidades </v-toolbar-title>
			</v-toolbar>
			<v-container v-if="loading" class="d-flex justify-center">
				<v-progress-circular 
					indeterminate
					color="primary"
				></v-progress-circular>
			</v-container>
			<template v-if="monthlyPayments.length > 0">
				<v-data-table
					:headers="headers"
					:items="monthlyPayments"
					item-key="idGrupo"
					class="elevation-1 font-weight-light caption td-click"
					fixed-header
				>
                    <template v-slot:[`item.fechaInicio`]="{item}">
                        {{formatDate(item.fechaInicio.substring(0, 10))}}
                    </template>
					<template v-slot:[`item.accion`]="{item}">
						<v-tooltip top>
							<template v-slot:activator="{on,attrs}">
								<v-btn
									icon
									color="success"
									class="mr-2"
									@click="seePayments(item)"
									v-bind="attrs"
									v-on="on"
								>
									<v-icon >mdi-cash-multiple</v-icon>
								</v-btn>
							</template>
							<span>Ver pagos</span>
						</v-tooltip>
					</template>
				</v-data-table>
			</template>
			<template v-else>
				<v-card elevation="0" outlined v-if="loading == false">
					<v-card-text class="text-center pb-0">No existen Mensualidades Registradas.</v-card-text>
					<v-card-text class="text-center pt-0">
						<v-btn color="blue darken-1" dark small class="my-2 px-2" tile elevation="0" @click="selectMonthlyPayments">Volver a Intentar</v-btn>
					</v-card-text>
				</v-card>
			</template>
			
		</v-container>
	</v-layout>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';
export default {
	components: {
	},
	data(){
		return{
			headers: [
				{text: "Semestre", value: "semestre", align: 'center', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
                {text: "Grupo", value: "grupo", align: 'left', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
				{text: "Turno", value: "turno", align: 'center', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
                {text: "Inicio de Clases", value: "fechaInicio", align: 'center', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
				{text: "Cuotas", value: "cuotas", align: 'center', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
				{text: "Mensualidad Bs.", value: "costo", align: 'center', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
                {text: "Acciones", value: "accion", align: 'center', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
			],
			monthlyPayments: [],
			loading: false,
			items: [
				{
					text: 'Mensualidades',
					disabled: false,
					href: '/monthly-payment',
				},
                /*{
					text: 'Grupos',
					disabled: true,
					href: '/group/'+this.$route.params.idSemester,
				},*/
			],
		}
	},
	computed:{
		...mapState(['user','idDepartament']),
	},
	created(){
		this.selectMonthlyPayments();
	},
	methods:{
		back(){
            this.$router.go(-1);
        },
		seePayments(item){
			this.$router.push(`monthly-payment/group/${item.idGrupo}/student/${this.user.idUsuario}`);
		},
		async selectMonthlyPayments() {
			this.loading = true;
			try {
				let response = await axios.get(`students/selectMonthlyPayments/${this.idDepartament}/${this.user.idUsuario}`);
				if (response.status == 200) {
					this.monthlyPayments = response.data;
					this.loading = false;
				}
			} catch (error) {
				console.log(error);
				this.loading = false;
			}
		},
		formatDate (date) {
            if (!date) return null

            const [year, month, day] = date.split('-')
            return `${day}/${month}/${year}`
        },
	}
}
</script>
