<template>
    <v-layout align-start>
        <v-container>
            <v-row class="pl-2 mb-0" align="center">
                <v-btn
                    text
                    icon
                    style="color: #909090;"
                    @click="back"
                >
                    <v-icon style="color: #909090">mdi-arrow-left-bold</v-icon>
                </v-btn>
                <v-breadcrumbs class="pl-1" :items="items">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            :disabled="item.disabled"
                        >
                            {{ item.text}}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </v-row>
            <v-card outlined tile class="pb-2">
                <v-card-title style="color: #1E88E5;" class="ml-2 pb-0">
                    Pagos
                </v-card-title>
                <v-card-text class="pb-0" >
                    <v-row class="ml-1">
                        <v-col cols="12" sm="12" md="12">
                            <p style="color:#4c4c4c;" class="mb-1 subtitle-2 font-weight-medium">Grupo: <span class="caption ">{{group.nombreGrupo}}</span></p>
                            <p style="color:#4c4c4c;" class="mb-1 subtitle-2 font-weight-medium">Ci: <span class="caption ">{{student.ci}}</span></p>
                            <p style="color:#4c4c4c;" class="mb-1 subtitle-2 font-weight-medium">Nombres: <span class="caption">{{student.nombres}}</span></p>
                            <p style="color:#4c4c4c;" class="mb-1 subtitle-2 font-weight-medium">Apellidos: <span class="caption">{{student.apellidos}}</span></p>
                            <p style="color:#4c4c4c;" class="mb-1 subtitle-2 font-weight-medium">Telefono: <span class="caption">{{student.telefono}}</span></p>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
            <v-data-table
                :headers="headers"
                :items="payments"
                class="elevation-1 font-weight-light caption"
                
                fixed-header
            >
                <template v-slot:[`item.nroMensualidad`]="{item}">
                    <v-icon :color="!item.paymentComplete ? 'error':'success'">{{!item.paymentComplete ? 'mdi-close-circle' : 'mdi-check-circle'}}</v-icon> {{item.nroMensualidad}}
                </template>
            </v-data-table>
        </v-container>
    </v-layout>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';
export default {
    components: {
    },
    data(){
        return {
            semester: {},
            group: {}, 
            items: [
				{
					text: 'Mensualidades',
					disabled: false,
					href: '/monthly-payment',
				},
                {
					text: 'Pagos',
					disabled: false,
					href: `/monthly-payment/group/${this.$route.params.idGroup}/student/${this.$route.params.idStudent}`,
				},
			],
            student: {},
            course: {},
            payments: [], 
            headers: [
				{text: "Nro Pagos", value: "nroMensualidad", align: 'center', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
				{text: "Mensualidad Bs.", value: "monto", align: 'center', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
				{text: "Descuento Bs.", value: "descuento", align: 'center', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
				//{text: "Total Bs.", value: "saldo", align: 'center', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
				{text: "Cancelado Bs.", value: "cancelado", align: 'center', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
                {text: "Fecha Límite", value: "fechaRegistro", align: 'center', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
                //{text: "Acciones", value: "accion", align: 'center', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
			],
            dialog: false,
            itemPayment: {},
			idPayment: '',
			paymentTypes: [],
            disabled: false,
        }
    },
    computed: {
        ...mapState(['idDepartament','user']),
        nombreGrupo(){
            return this.group.nombreGrupo;
        },
    },
    created(){
        this.selectGroupById();
        this.selectUserById();
        this.selectMonthlyPayments();
		this.selectPaymentTypes();
    },
    methods: {
        back(){
            this.$router.go(-1);
        },
        async selectUserById(){
            try {
                let response = await axios.get(`users/selectUserById/${this.$route.params.idStudent}`);
                if (response.status == 200) {
                    this.student = response.data;
                }
            } catch (error) {
                console.log(error);
            }
        },
        async selectStudentCourseByIdGroupAndIdUser(group){
            let date = new Date(group.fechaInicio);
                    date.setDate(date.getDate()+1);
                    for (let index = 0; index < group.cuotas; index++) {
                       
                        this.payments.push(
                            {
                                nro: index+1,
                                mensualidad: group.costo,
                                descuento: 0,
                                total: group.costo,
                                cancelado: 0,
                                fechaLimite: date.toLocaleDateString(),
                                estado: true,
                            }
                        );
                        date.setMonth(date.getMonth()+1)
                        
                    }
            try {
                let response = await axios.get(`courses/selectStudentCourseByIdGroupAndIdUser/${this.$route.params.idGroup}/${this.$route.params.idStudent}`);
                if (response.status == 200) {
                    this.course = response.data;
                    this.selectMonthlyPayments(response.data['idGrupoUsuario']);
                }
            } catch (error) {
                console.log(error);
            }
        }, 
        async selectPayments(idMonthlyPayment){
            let response = await axios.get(`payments/selectPayments/${idMonthlyPayment}`);
            return response;
        },
        async selectPaymentsByIdGroupAndIdUser(idGroup,idUser){
            let response = await axios.get(`payments/selectPaymentsByIdGroupAndIdUser/${idGroup}/${idUser}`);
            return response;
        },
        async selectMonthlyPayments() {
            try {
                this.payments = [];
                let response = await axios.get(`monthlypayments/SelectMonthlyPayments/${this.$route.params.idGroup}/${this.$route.params.idStudent}`);
                let response2 = await axios.get(`payments/selectPaymentsByIdGroupAndIdUser/${this.$route.params.idGroup}/${this.$route.params.idStudent}`);
                if (response.status == 200 && response2.status == 200) {
                    if (response2.data.length > 0) {
                        response.data.map((value) => {
                            value.fechaRegistro = this.formatDate(value.fechaRegistro);
                        });
                        response2.data.map((value,index) => {
                            value.fechaRegistro = this.formatDate(value.fechaRegistro);
                            value.fechaPago = this.formatDateTime(value.fechaPago);
                            this.payments.push({
                                cancelado: value.subTotal,
                                estado: value.estado,
                                fechaRegistro: value.fechaRegistro,
                                fechaPago: value.fechaPago,
                                idGrupoUsuario: value.idGrupoUsuario,
                                idMensualidad: value.idMensualidad, 
                                monto: value.subTotal,
                                montoMensualidad: value.monto,
                                descuento: value.descuento,
                                descripcion: value.descripcion,
                                nroMensualidad: value.nroMensualidad,
                                idPago: value.idPago,
                                codigo: value.codigo,
								acuenta: value.acuenta,
                                saldo: value.saldo,
                                active: index+1 == response2.data.length ? true : !value.cancelado,
                                paymentActive: !value.cancelado,
								paymentComplete: value.cancelado,
								idTipoPago: value.idTipoPago,
								tipoPago: value.nombreTipoPago,
                                nombres: value.nombres,
                                apellidos: value.apellidos,
                            });

                            if (index+1 == response2.data.length) {
                                if(value.saldo > 0){
                                    this.payments.push({
                                        cancelado: 0,
                                        estado: value.estado,
                                        fechaRegistro: value.fechaRegistro,
                                        fechaPago: value.fechaPago,
                                        idGrupoUsuario: value.idGrupoUsuario,
                                        idMensualidad: value.idMensualidad, 
                                        monto: value.saldo,
                                        montoMensualidad: value.monto,
                                        descuento: 0,
                                        nroMensualidad: value.nroMensualidad,
                                        saldo: value.saldo,
                                        active: false,
                                        paymentActive: true,
										paymentComplete: false,
                                    });
                                }
                                for (let i = value.nroMensualidad; i < response.data[0].cuotas; i++) {
                                    this.payments.push({
                                        cancelado: 0,
                                        estado: response.data[i].estado,
                                        fechaRegistro: response.data[i].fechaRegistro,
                                        idGrupoUsuario: response.data[i].idGrupoUsuario,
                                        idMensualidad: response.data[i].idMensualidad, 
                                        monto: response.data[i].monto,
                                        descuento: 0,
                                        nroMensualidad: response.data[i].nroMensualidad,
                                        saldo: response.data[i].saldo,
                                        active: false,
                                        paymentActive: response.data[i].cancelado,
										paymentComplete: false
                                    });
                                }

                            }
                        });
                    }else{
                        response.data.map((value,index) => {
                            if (index == 0 && value.cancelado == false) {
                                value.cancelado = true;
                            }
                            value.fechaRegistro = this.formatDate(value.fechaRegistro);
                            this.payments.push({
                                cancelado: 0,
                                estado: value.estado,
                                fechaRegistro: value.fechaRegistro,
                                idGrupoUsuario: value.idGrupoUsuario,
                                idMensualidad: value.idMensualidad, 
                                monto: value.monto,
                                descuento: 0,
                                nroMensualidad: value.nroMensualidad,
                                saldo: value.saldo,
                                active: false,
                                paymentActive: value.cancelado,
								paymentComplete: false
                            });
                        });
                    }

					if (this.idPayment > 0) {
						const item = this.payments.find(value => value.idPago === this.idPayment);
						this.generateReport(item);
					}
                }
            } catch (error) {
                console.log(error);
            }
        },
        async selectGroupById(){
            try {
                let response = await axios.get(`groups/selectGroupById/${this.$route.params.idGroup}`);
                if (response.status == 200) {
                    this.group = response.data;
                    
                }
            } catch (error) {
                console.log(error);
            }
        },
		async selectPaymentTypes(){
			try {
				let response = await axios.get(`paymentTypes/selectPaymentType/1/${this.idDepartament}`);
				if (response.status == 200) {
					this.paymentTypes = response.data;
				}
			} catch (error) {
				console.log(error);
			}
		},
        formatDate (date) {
            if (!date) return null;
            let dateSub = date.substring(0, 10);
            const [year, month, day] = dateSub.split('-');
            return `${day}/${month}/${year}`;
        },
		formatDateTime(date){
			if (!date) return null;
			let dateSub = date.substring(0,10);
			let timeSub = date.substring(11,19);
			const [year,month,day] = dateSub.split('-');
			return `${day}-${month}-${year} ${timeSub}`
		}
    }
}
</script>
