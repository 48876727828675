<template>
    <v-layout>
        <v-container>
			<v-row class="pl-2 mb-0" align="center">
                <v-btn
                    text
                    icon
                    style="color: #909090;"
                    @click="back"
                >
                    <v-icon style="color: #909090">mdi-arrow-left-bold</v-icon>
                </v-btn>
				<v-breadcrumbs class="pl-1" :items="items">
					<template v-slot:item="{ item }">
						<v-breadcrumbs-item
							:to="item.href"
							:disabled="item.disabled"
						>
							{{ item.text}}
						</v-breadcrumbs-item>
					</template>
				</v-breadcrumbs>
			</v-row>
			<v-card outlined tile>
				<v-card-text class="py-0">
					<v-container class="px-0">
						<v-row>
							<v-col cols="12" sm="4" md="4">
								<p class="font-weight-medium text-h6 mb-0">Avatar</p>
								<p class="mb-0 text-body-2">Puede cambiar su avatar aquí o eliminar el actual</p>
							</v-col>
							<v-col cols="12" sm="8" md="8">
								<v-row no-gutters>
									<v-col cols="4" sm="2" md="2">
										<v-avatar size="96">
											<v-img
												:src="formStudent.image"
											></v-img>
										</v-avatar>
									</v-col>
									<v-col cols="8" sm="10" md="10">
										<p class="mb-0 font-weight-bold text-body-2">Subir nuevo avatar</p>
										<v-btn class="my-2" outlined small @click="chooseImage">
											<p class="mb-0 text-body-2 text-capitalize font-weight-medium">Elija el archivo</p> 
										</v-btn>
										<input
											v-show="false"
											ref="image"
											accept="image/*"
											type="file"
											@change="changeImage"
										/>
										<p class="mb-0 text-body-2 grey--text">El tamaño máximo de archivo permitido es de 200 KB.</p>
										<v-btn v-if="formStudent.image != null" class="my-2" color="error" outlined small @click="deleteImage">
											<p class="mb-0 text-body-2 text-capitalize font-weight-normal">Eliminar avatar</p> 
										</v-btn>
									</v-col>
								</v-row>
							</v-col>
						</v-row>
					</v-container>
					<v-divider />
					<v-container class="px-0">
						<v-row>
							<v-col cols="12" sm="4" md="4">
								<p class="font-weight-medium text-h6 mb-0">Informacion Personal</p>
								<p class="mb-0 text-body-2">Esta informacion aparecera en tu perfil</p>
							</v-col>
							<v-col cols="12" sm="8" md="8">
								<v-container>
									<v-form ref="form">
										<v-row>
											<v-col
												cols="12"
												sm="6"
												md="6"
												class="py-0"
											>
												<p class="mb-2 text-body-2 font-weight-bold">Nombres</p>
												<v-text-field
													v-model="formStudent.names"
													class="pt-0"
													label="Nombres"
													placeholder="Nombres"
													single-line
													dense
													outlined
													autocomplete="null"
													:rules="nameRules"
												>
												</v-text-field>
											</v-col>
											<v-col
												cols="12"
												sm="6"
												md="6"
												class="py-0"
											>
												<p class="mb-2 text-body-2 font-weight-bold">Apellidos</p>
												<v-text-field
													v-model="formStudent.surnames"
													class="pt-0"
													label="Apellidos"
													placeholder="Apellidos"
													single-line
													dense
													outlined
													autocomplete="null"
													:rules="surnameRules"
												>
												</v-text-field>
											</v-col>
											<v-col
												cols="12"
												sm="6"
												md="6"
												class="py-0"
											>
												<p class="mb-2 text-body-2 font-weight-bold grey--text">Ci</p>
												<v-text-field
													v-model="formStudent.ci"
													class="pt-0"
													label="Ci"
													placeholder="Ci"
													single-line
													dense
													outlined
													autocomplete="null"
													:rules="ciRules"
													disabled
												>
												</v-text-field>
											</v-col>
											<v-col
												cols="12"
												sm="6"
												md="6"
												class="py-0"
											>
												<p class="mb-2 text-body-2 font-weight-bold">Telefono</p>
												<v-text-field
													v-model="formStudent.phone"
													class="pt-0"
													label="Telefono"
													placeholder="Telefono"
													single-line
													dense
													outlined
													autocomplete="null"
													:rules="phoneRules"
												>
												</v-text-field>

											</v-col>
											<v-col
												cols="12"
												sm="12"
												md="12"
												class="py-0"
											>
												<p class="mb-2 text-body-2 font-weight-bold">Email</p>
												<v-text-field
													v-model="formStudent.email"
													class="pt-0"
													label="Email"
													placeholder="Email"
													single-line
													dense
													outlined
													autocomplete="null"
												>
												</v-text-field>

											</v-col>
										</v-row>
									</v-form>
								</v-container>
							</v-col>
						</v-row>
					</v-container>
					<v-divider />
					<v-container class="px-0">
						<v-btn elevation="0" color="#1486bd" dark @click="editProfile" :loading="loadingData">
							<p class="mb-0 text-body-2 text-capitalize font-weight-medium">Actualizar Perfil</p> 
						</v-btn>
					</v-container>
				</v-card-text>
			</v-card>
			<v-snackbar
				v-model="snackbar"
				:color="colorSnackbar"
                text
			>
				<span class="text-center">{{ textSnackbar }}</span>
			</v-snackbar>
        </v-container>
    </v-layout>
</template>

<script>
import Compressor from 'compressorjs';
import axios from 'axios';
import { mapState, mapMutations } from 'vuex';
export default {
    data(){
        return{
            items: [
				{
					text: 'Perfil',
					disabled: false,
					href: '/profile',
				},
			],
			formStudent:{
                idStudent: '',
				names: '',
				surnames: '',
				ci: '',
				email: '',
				phone: '',
				idBranchoffice: '',
				idRole: 6,
                creationDate: new Date().toISOString().substring(0, 10),
				user: '',
				password: '',
				image: null
            },
			nameRules: [
				value => !!value || 'Ingrese nombres',
			],
            surnameRules: [
                value => !!value || 'Ingrese apellidos'
            ],
            ciRules: [
                value => !!value || 'Ingrese un ci'
            ],
            emailRules: [
                value => !!value || 'Ingrese un email',
                value => /.+@.+\..+/.test(value) || 'Debe ingresar un email correcto',
            ],
            phoneRules: [
                value => !!value || 'Ingrese un numero de telefono',
				value => /^(\d{8}-\d{8}|\d{8})$/.test(value) || 'Debe ingresar un formato correcto'
            ],
			branchofficeRules: [
                value => !!value || 'Seleccione una sucursal'
            ],
			loadingData: false,
			snackbar: false,
			colorSnackbar: '',
			textSnackbar: '',
        }
    },
	computed:{
		...mapState(['user']),
	},
	created(){
		this.loadUser();
	},
	methods:{
		...mapMutations(['setUser']),
		back(){
            this.$router.go(-1);
        },
		async updateStudentProfile(data){
			try {
				let response = await axios.put('users/updateStudentProfile',data);
				if (response.status == 200) {
					this.loadingData = false;
					this.notification('success',response.data['msg']);
					this.setUser(response.data.data);
				}
			} catch (error) {
				console.log(error);
				this.loadingData = false;
				this.notification('error','Ocurrio algo inesperado. Por favor intente de nuevo.');
			}
		},
		editProfile(){
			if (this.$refs.form.validate()) {
				this.loadingData = true;
				let data = {
					names: this.formStudent.names,
					surnames: this.formStudent.surnames,
					email: this.formStudent.email,
					phone: this.formStudent.phone,
					idStudent: this.formStudent.idStudent,
					ci: this.formStudent.ci,
					image: this.formStudent.image
				}
				this.updateStudentProfile(data);
			}
		},
		deleteImage(){
			this.formStudent.image = null;
		},
		chooseImage(){
			this.$refs.image.click();
		},
		loadUser(){
			console.log(this.user);
			this.formStudent.idStudent = this.user.idUsuario;
			this.formStudent.names = this.user.nombres;
			this.formStudent.surnames = this.user.apellidos;
			this.formStudent.ci = this.user.ci;
			this.formStudent.phone = this.user.telefono;
			this.formStudent.email = this.user.email;
			this.formStudent.image = this.user.avatar;
		},
		async changeImage(event){
			let fileReader = new FileReader();
			fileReader.addEventListener("load", () => {
				this.formStudent.image = fileReader.result;
			});
			
			this.compressImage(event.target.files[0], (compressedFile, error) => {
				if (error) {
					console.error(error);
				} else {
					fileReader.readAsDataURL(compressedFile);
					console.log(compressedFile)
				}
			});
		},
		async compressImage(file, callback) {
			new Compressor(file, {
				quality: 0.6,
				maxWidth: 400,
				maxHeight: 400,
				success: (compressedFile) => {
					callback(compressedFile, null);
				},
				error: (error) => {
					callback(null, error);
				},
			});
		},
		notification(type,text){
			this.snackbar = true;
			this.colorSnackbar = type;
			this.textSnackbar = text;
		},
	}
};
</script>

<style>

</style>