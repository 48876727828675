<template>
    <v-layout>
        <v-container>
            <v-row class="pl-2 mb-0" align="center">
                <v-btn
                    text
                    icon
                    style="color: #909090;"
                    @click="back"
                >
                    <v-icon style="color: #909090">mdi-arrow-left-bold</v-icon>
                </v-btn>
                <v-breadcrumbs class="pl-1" :items="items">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            :disabled="item.disabled"
                        >
                            {{ item.text}}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </v-row>
            <v-card outlined>
                <v-card-text>
                    <p class="text-h6 mb-0">Kardex</p>
                    <v-container>
                        <v-form ref="formGrade">
                        <v-row class="d-flex flex-row">
                            <v-col
                                cols="12"
                                sm="3"
                                md="3"
                                class="py-0"
                            >
                                <p class="mb-2">Gestion</p>
                                <v-autocomplete
                                    v-model="formGroup.idGroup"
                                    :items="semesters"
                                    :filter="semesterFilter"
                                    outlined
                                    dense
                                    item-text="gestion"
                                    item-value="idGrupo"
                                    label="Seleccione"
                                    single-line
                                    :rules="semesterRules"
                                    @change="findGestion"
                                    no-data-text="No te inscribiste a ningun grupo"
                                ></v-autocomplete>
                            </v-col>
                            <!--v-col
                                cols="12"
                                sm="3"
                                md="3"
                                class="py-0"
                            >
                                <p class="mb-2">Grupo</p>
                                <v-autocomplete
                                    v-model="formGroup.idGroup"
                                    :items="groups"
                                    :filter="groupFilter"
                                    outlined
                                    dense
                                    item-text="nombreGrupo"
                                    item-value="idGrupo"
                                    label="Seleccione"
                                    single-line
                                    @change="findSubject"
                                    :rules="groupRules"
                                    no-data-text="No hay grupos disponibles"
                                ></v-autocomplete>
                            </v-col>
                            <v-col
                                cols="12"
                                sm="3"
                                md="3"
                                class="py-0"
                            >
                                <p class="mb-2">Materia</p>
                                <v-select
                                    v-model="formGroup.idSubject"
                                    :items="subjects"
                                    item-text="nombreMateria"
                                    item-value="idMateria"
                                    label="Seleccione"
                                    persistent-hint
                                    dense
                                    outlined
                                    single-line
									@change="changeSubject"
                                    :rules="subjectRules"
                                ></v-select>
                            </v-col-->
                            <v-col
                                cols="12"
                                sm="3"
                                md="3"
                                class="py-0"
                                align-self="center"
                            >
                                <v-btn
                                    color="#1486bd"
                                    dark
                                    elevation="0"
                                    :loading="loading"
                                    @click="findStudents"
                                >
                                    <span class="text-capitalize text-body">Ver Kardex</span> 
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                    </v-container>
                </v-card-text>
            </v-card>
			<v-container v-if="loading" class="d-flex justify-center">
				<v-progress-circular 
					indeterminate
					color="primary"
				></v-progress-circular>
			</v-container>
			<template v-if="students.length > 0">
				<v-data-table
					:headers="headers"
					:items="students"
                    hide-default-footer
					item-key="idGrupoMateria"
					class="elevation-1 font-weight-light caption td-click"
					fixed-header
				>
				</v-data-table>
			</template>
			<v-snackbar
				v-model="snackbar"
				:color="colorSnackbar"
				text
			>
				<span class="text-center">{{ textSnackbar }}</span>
			</v-snackbar>
        </v-container>
    </v-layout>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';
export default {
    data(){
		return {
			dialog: false,
			headers: [],
			students: [],
            semesters: [],
            formGroup:{
                idGroup: '',
                idSemester: '',
                idSubject: '',
            },
            semesterRules: [
                value => !!value || 'Seleccione un semestre'
            ],
            groupRules: [
                value => !!value || 'Seleccione un grupo'
            ],
            subjectRules: [
                value => !!value || 'Seleccione una materia',
            ],
			loading: false,
			loadingData: false,
			snackbar: false,
			colorSnackbar: '',
			textSnackbar: '',
			items: [
				{
					text: 'Kardex',
					disabled: false,
					href: '/kardex',
				},
			],
			formGrade: {
				idGroup: '',
				idStudent: '',
				idTeacher: '',
				idSubject: '',
				dateIngress: '',
				partial: '',
				grade: '',
			},
            drills: 0,
		}
	},
	computed: {
		...mapState(['user','idDepartament']),
	},
	watch: {
	},
	created () {
        this.selectSemesterGroup();
	},
	methods: {
        back(){
            this.$router.go(-1);
        },
        findGestion(item){
            const obj = this.semesters.find(value => value.idGrupo === item);
            this.drills = obj.cuotas;
            this.headers = [];
            this.defineHeaders();
        },
        defineHeaders(){
			this.headers.push(
				{text: "Nro", value: "nro", align: 'center', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
				{text: "Materia", value: "nombreMateria", align: 'left', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
			);
			for (let i = 0; i < this.drills; i++) {
				this.headers.push(
					{text: `S${i+1}`, value: `s${i+1}`, align: 'center', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
				);
			}
			/*this.headers.push(
				{text: "NFin", value: "nfin", align: 'center', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
			);*/
		},
        async selectGradeStudentByIdGroup(idGroup){
			this.students = [];
            try {
                let response = await axios.get(`students/selectGradeStudentByIdGroup/${idGroup}`,{params:{idStudent: this.user.idUsuario}});
                if (response.status == 200) {
                    if (response.data.length > 0) {
                        let s1 = 0;
                        let s2 = 0;
                        let s3 = 0;
                        let s4 = 0;
                        let s5 = 0;
                        let s6 = 0;
                        response.data.map((value,index) => {
                            s1 = s1+value.s1;
                            s2 = s2+value.s2;
                            s3 = s3+value.s3;
                            s4 = s4+value.s4;
                            s5 = s5+value.s5;
                            s6 = s6+value.s6;
                            this.students.push({
                                nro: index+1,
                                idGrupoMateria: value.idGrupoMateria,
                                nombreMateria: value.nombreMateria,
                                s1: value.s1,
                                s2: value.s2,
                                s3: value.s3,
                                s4: value.s4,
                                s5: value.s5,
                                s6: value.s6,
                                nfin: ((Number(value.s1)+Number(value.s2)+Number(value.s3)+Number(value.s4)+Number(value.s5)+Number(value.s6))/this.drills).toFixed(1),
                            });
                        });
                        this.students.push({
                            nombreMateria: 'NOTA FINAL',
                            s1: (s1/response.data.length).toFixed(1),
                            s2: (s2/response.data.length).toFixed(1),
                            s3: (s3/response.data.length).toFixed(1),
                            s4: (s4/response.data.length).toFixed(1),
                            s5: (s5/response.data.length).toFixed(1),
                            s6: (s6/response.data.length).toFixed(1),
                        });
                    }else{
                        this.notification('warning','El grupo no cuenta con materias')
                    }
                    this.loading = false;
                }
            } catch (error) {
                console.log(error);
                this.loading = false;
            }
        },
        findStudents(){
            if (this.$refs.formGrade.validate()) {
                this.loading = true;
                this.students = [];
                this.selectGradeStudentByIdGroup(this.formGroup.idGroup);
            }
        },
        semesterFilter (item, queryText) {
			const textOne = item.gestion.toLowerCase();
			const searchText = queryText.toLowerCase();

			return textOne.indexOf(searchText) > -1;
		},
        async selectSemesterGroup(){
            try {
                let response = await axios.get(`students/selectSemesterGroup/${this.idDepartament}`,{params: {idStudent: this.user.idUsuario}});
                if (response.status == 200) {
                    this.semesters = response.data;
                }
            } catch (error) {
                console.log(error);
            }
        },

		notification(type,text){
			this.snackbar = true;
			this.colorSnackbar = type;
			this.textSnackbar = text;
		},
        formatDate (date) {
            if (!date) return null

            const [year, month, day] = date.split('-')
            return `${day}/${month}/${year}`
        },
    }
}
</script>

<style>

</style>