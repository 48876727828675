import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'
import LoginView from '../views/login-view.vue'
import MonthlyPaymentView from '../views/monthly-payment-view.vue'
import ProfileView from '../views/profile-view.vue'
import PaymentStudentView from '../views/payment-student-view.vue'
import KardexStudentView from '../views/kardex-student-view.vue'
import HomeView from '../views/home-view.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'login'
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/monthly-payment',
    name: 'monthly-payment',
    component: MonthlyPaymentView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/monthly-payment/group/:idGroup/student/:idStudent',
    name: 'payments',
    component: PaymentStudentView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/profile',
    name: 'profile',
    component: ProfileView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/kardex',
    name: 'kardex',
    component: KardexStudentView,
    meta: {
      requiresAuth: true
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  let user = store.state.user;
  let authorization = to.matched.some(record => record.meta.requiresAuth);

  if (authorization && !user) {
    next('login');
  } else if (!authorization && user) {
    next('home');
  } else {
    next();
  }

});

export default router
