<template>
    <v-container class="login" fluid>
    <img :src="require('../assets/whiteLogo2.png')" height="120" class="img" alt="...">
    <div class="d-flex d-flex-row justify-center align-self-center" style="height: 100%">
        <!--v-col class="" align-self="center" -->
        <div class="d-flex d-flex-column align-self-center">
            <v-card class="rounded-lg" elevation="6" >
                <!--v-row-->
                    <!--v-col class="pa-0 d-none d-sm-flex" cols="0" sm="6" md="6" lg="6">
                        <div class="triangle-orange"></div>
                    </v-col-->
                    <v-col cols="12" sm="12" md="12" lg="12">
                        <p class="text-center text-h4 mt-10 font-weight-bold" style="color: #1486bd;">BIENVENIDO</p>
                        <span class="mb-8 pl-6 text-caption text-left">Inicia sesion con tu Ci</span>
                        <v-container class="d-flex flex-column justify-center align-center mt-0 mb-10 pl-6 pr-6">
                            <v-form 
                                ref="form"
                                v-model="valid"
                            >
                                <v-text-field
                                    v-model="user"
                                    style="width: 100%"    
                                    class="pt-0"
                                    label="CI"
                                    outlined
                                    single-line
                                    dense
                                    prepend-icon="mdi-card-account-details"
                                    :rules="rulesUser"
                                    @keyup.native.enter="enter"
                                ></v-text-field>
                                <v-btn class="mt-4" color="#1486bd" dark large rounded block @click="enter" :loading="loadingLogin"> 
                                    <p class="text-capitalize mb-0">Ingresar</p>
                                </v-btn>
                            </v-form>
                        </v-container>
                    </v-col>
                <!--/v-row-->
            </v-card>
        </div>
        <!--/v-col-->
    </div>
    <v-snackbar
      v-model="snackbar"
      :color="colorSnackbar"
    >
        {{ textSnackbar }}
    </v-snackbar>
    </v-container>
</template>

<script>
import axios from 'axios';
import { mapActions } from 'vuex';
export default {
    data(){  
        return {
            showPassword: false,
            valid: true,
            user: '',
            password: '',
            loadingLogin: false,
            rulesUser: [
                value => !!value || 'Ingrese un CI',
            ],
            rulesPassword: [
                value => !!value || 'Ingrese una contraseña',
            ],
            snackbar: false,
            textSnackbar: '',
            colorSnackbar: '',
        }
    },
    computed:{

    },
    created(){

    },
    methods: {
        ...mapActions(['autoLogin']),
        async login(data){
            try {
                let response = await axios.post('users/loginStudent',data);
                if (response.status == 200) {
                    let token = response.data.token;
                    let expiration = response.data.expiration;
                    this.notificacion('success', 'Bienvenido al Sistema Fantasma.');
                    this.$store.dispatch("saveToken", token);
                    this.$store.dispatch("saveExpiration", expiration);
                    this.autoLogin();
                    this.loadingLogin = false;
                }
            } catch (error) {
                console.log(error);
                this.loadingLogin = false;
                if (error.response.status == 401) {
                    this.notificacion('error', error.response.data);
                }else{
                    this.notificacion('error', 'Ocurrio algo inesperado. Por favor intente de nuevo.');
                }
                
            }
            
        },
        enter(){
            if (this.$refs.form.validate()) {
                this.loadingLogin = true;
                let user = this.user.replace(/ /g,"");
                let data = {
                    ci: user.trim()
                }

                this.login(data);
            }
        },
        notificacion(type, message){
            this.snackbar = true;
            this.textSnackbar = message;
            this.colorSnackbar = type;
        }
    }
}
</script>

<style scoped>
.login{
    height: 100vh; 
    background-color: #1486bd;
    background-image: radial-gradient(circle at bottom right, #18a6df 50%, transparent 50%);
    background-size: 110% 130%;
    background-position: left bottom, right bottom;
    background-repeat: no-repeat;
}
.img{
    position: absolute;
    object-fit: contain;
    object-position: right bottom;
    margin-left: 18px;
    margin-top: 10px;
}
/*paleta de colores primary: #1874BF secondary: #1CA5DB */
/*nueva paleta de colores primary: #1486bd secondary: #18a6df*/
</style>