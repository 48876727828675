<template>
    <v-container class="fill-height" fluid>
        <v-row justify="center" align="center">
            <v-col cols="12">
                <v-row justify="center" align="center">
                    <v-card
                    @click="active(1)"
                    hover
                    width="155"
                    height="155"
                    class="mx-3 white font-weight-bold mt-2 rounded-xl"
                    align="center"
                    >
                        <v-icon color="#606060" class="mt-2" size="90" mt-2>mdi-account-cash</v-icon>
                        <v-card-text style="color: #909090;" class="font-weight-bold">MENSUALIDAD</v-card-text>
                    </v-card>
                    <v-card
                    @click="active(2)"
                    hover
                    width="155"
                    height="155"
                    class="mx-3 white font-weight-bold mt-2 rounded-xl"
                    align="center"
                    >
                        <v-icon color="#606060" class="mt-2" size="90" mt-2>mdi-archive</v-icon>
                        <v-card-text style="color: #909090;" class="font-weight-bold">KARDEX</v-card-text>
                    </v-card>
                    <v-card
                    @click="active(3)"
                    hover
                    width="155"
                    height="155"
                    class="mx-3 white font-weight-bold mt-2 rounded-xl"
                    align="center"
                    >
                        <v-icon color="#606060" class="mt-2" size="90" mt-2>mdi-account</v-icon>
                        <v-card-text style="color: #909090;" class="font-weight-bold">PERFIL</v-card-text>
                    </v-card>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    data(){
        return{

        }
    },
    computed:{

    },
    created(){

    },
    methods:{
        active(item){
            if (item == 1) {
                this.$router.push('monthly-payment');
            }else if(item == 2){
                this.$router.push('kardex');
            }else if(item == 3){
                this.$router.push('profile');
            }
        }
    }
}
</script>

<style>

</style>