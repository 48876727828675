import Vue from 'vue'
import Vuex from 'vuex'
import decode from 'jwt-decode'
import router from '../router/index'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        token: null,
        user: null,
        expiration: null,
        session: false,
        drawer: false,
        appIcon: false,
        idDepartament: null,
        optionsStudent: [],
    },
    getters: {
    },
    mutations: {
        setToken(state, token) {
			state.token = token
		},
		setUser(state, user) {
			state.user = user
		},
		setExpiration(state, value){
			state.expiration = value;
		},
		setSession(state, value){
			state.session = value;
		},
		setDrawer(state, value){
			state.drawer = value;
		},
		setAppIcon(state, value){
			state.appIcon = value;
		},
        setIdDepartament(state, value){
			state.idDepartament = value;
		},
        setOptionsStudent(state, value){
            state.optionsStudent = value;
        }
    },
    actions: {
        saveToken({ commit }, token) {
			commit("setToken", token)
			commit("setUser", decode(token))
			let user = decode(token);
			localStorage.setItem("token", token)
			localStorage.setItem("idUsuario", user.idUsuario)
		},
		saveExpiration({commit}, expiration){
			commit("setExpiration", expiration)
			localStorage.setItem("expiration", expiration)
		},
		autoLogin({ commit }) {
			let token = localStorage.getItem("token")
			if(token){
				commit("setToken", token);
				commit("setUser", decode(token));
				commit('setSession',true);
				
                commit("setIdDepartament",this.state.user.idDepartamento);
                commit("setAppIcon",true);
                router.replace({name: 'home'});
				
			}
		},
		logout({ commit }) {
			commit("setToken", null)
			commit("setUser", null)
			commit("setExpiration", null)
			commit("setDrawer",false)
			localStorage.removeItem("token")
			localStorage.removeItem("user")
			localStorage.removeItem("expiration")
			router.replace({ name: 'login' })
		}
    },
    modules: {
    }
})
